import React, { Component } from 'react';
import { Checkbox, Segment } from 'semantic-ui-react';

class NodeDataReportingPeriodDate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            usePrecedingPeriod: props.node.data.usePrecedingPeriod || false,
        };
    }

    getData () {
        return this.state;
    }

    render () {
        return (
            <Segment>
                <Checkbox
                    label='Brug dato fra forrige periode?'
                    checked={this.state.usePrecedingPeriod}
                    onChange={(_, { checked }) => this.setState({ usePrecedingPeriod: checked })}
                />
            </Segment>
        );
    }
}

export default NodeDataReportingPeriodDate;