import { useState } from 'react';
import { Button, Segment } from 'semantic-ui-react';
import erst from 'http/erst';

const XBRLTester = ({ xbrl, supplementaryXbrl, isIXBRL }) => {
    const [working, setWorking] = useState(false);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);

    const xbrlKind = isIXBRL ? 'iXBRL' : 'XBRL';

    const testXBRL = async () => {
        setWorking(true);
        setError(null);
        setResult(null);

        const request = {
            supplementaryInstanceXml: supplementaryXbrl,
        };

        if (isIXBRL) {
            request.ixbrl = xbrl;
        } else {
            request.xbrlXml = xbrl;
        }

        try {
            const result = await erst.testXBRL(request);
            setResult(result);
        } catch (e) {
            setError(e.message);
        }

        setWorking(false);
    };

    const renderContent = () => {
        if (error)   return <Segment content={error} color='red' />;
        if (working) return <Segment content='Sender XBRL til Erhversstyrelsen...' />;
        if (!result) return <Segment content={`${xbrlKind} ikke afsendt`} />;

        return (
            <>
                <Segment>
                    Statuskode <strong>{result.statusCode}</strong>{result.statusText && `: ${result.statusText}`}
                </Segment>
                {result.statusDetail && <Segment content={<div dangerouslySetInnerHTML={{ __html: result.statusDetail }} />} />}
            </>
        );
    };

    return (
        <Segment.Group>
            {renderContent()}
            <Segment secondary>
                <Button
                    primary
                    content={`Send ${xbrlKind} til test`}
                    icon='upload cloud'
                    onClick={testXBRL}
                    disabled={working}
                    loading={working}
                />
            </Segment>
        </Segment.Group>
    );
};

export default XBRLTester;
